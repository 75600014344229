.planCard {
  border: 1px solid #dfe4ed;
  border-radius: 4px;
  background-color: var(--bg);
  display: grid;
  margin-bottom: var(--space-12);
  height: 100%;
}

.planCard .sub {
  background: var(--bg100);
  padding: var(--space-16);
  display: grid;
  grid-row-gap: var(--space-12);
}

.planCard .sub .plan{
    background: var(--blue200);
    padding: var(--space-4) var(--space-8);
    width: fit-content;;
    display: flex;
    color: var(--blue500);
    font-weight: 600;
    border-radius: 4px;
    align-items: center;
  }

.planCard .status {
  padding: var(--space-12) var(--space-16);
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: var(--space-12);
  align-items: center;
}

.planCard .status .currentStatus {
  display: grid;
  grid-row-gap: var(--space-4);
}

.currentStatus .currentStatusTag {
  color: var(--green500);
  font-weight: 600;
}

.upgradeIcon{
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.upgradeIcon svg g{
  fill: var(--orange500);
}
.upgradeText {
  color: var(--orange500);
  font-weight: 600;
}

.otherInfo{
    margin: 0 var(--space-16);
    border-bottom: 1px solid var(--bg300);
    padding: var(--space-12) 0;
}

.upgradeButton{
    cursor: pointer;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    grid-column-gap: 8px;
}