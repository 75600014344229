.container{
    width: 100%;
    height: 100%;
}
.background{
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100%;
}
.background img {
    width: 100%;
    height: 100%;
}
.content {
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
}
.logo{
    align-self: flex-end;
    justify-content: center;
    display: grid;
}
.keySpider{
    margin-bottom: var(--space-32);
}
.keySpider svg{
    width: unset!important;
}
.card{
    background: var(--bg);
    border-radius: 8px;
    box-shadow: var(--shadow-16dp);
    padding: var(--space-32);
    width: 35rem;
    align-self: flex-start;
}