.page{
    background: var(--bg200);
    height: 100%;
}
.content {
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
}

.logo{
    align-self: flex-end;
    justify-content: center;
    display: grid;
    margin-bottom: var(--space-24);
}
.keySpider svg{
    width: 24rem!important;
}

.card{
    background: var(--white);
    box-shadow: var(--shadow-4dp);
    border-radius: 8px;
    display: grid;
    align-items: center;
    text-align: center;
    padding: var(--space-24) var(--space-48);
    align-self: flex-start;
}

.trailExpired svg{
    height: 20rem;
    width: unset;
}

.textContent{
    display: grid;
    max-width: 35rem;
    padding-bottom: var(--space-20);
    grid-row-gap: var(--space-8);
}

.resendConfirmation{
    display: grid;
    grid-row-gap: var(--space-12);
    align-items: center;
    text-align: center;
}