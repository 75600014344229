/* Let's get this party started */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
 
/* Track */
::-webkit-scrollbar-track {
    /* border-radius: 10px; */
    background: #ececec;
    box-shadow: inset 0 0 1px 0px rgb(0 0 0 / 5%);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #bfbfbf;
}
