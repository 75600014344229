.title900 {
  font-family: var(--font-family);
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -1.32px;
  line-height: 44px;
  color: var(--textbase);
}

.header800 {
  font-family: var(--font-family);
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -1.2px;
  line-height: 38px;
  color: var(--textbase);
}

.header700 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.8px;
  line-height: 32px;
  color: var(--textbase);
}

.header600 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 28px;
  color: var(--textbase);
}

.header500 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  color: var(--textbase);
}

.body400 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  color: var(--textbase);
}

.paragraph300 {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
  color: var(--textbase);
}

.paragraph200 {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  color: var(--gray900);
}

.tiny100 {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  color: var(--text400);
}

.weight400 {
  font-weight: 400;
}

.weight500 {
  font-weight: 500;
}

.weight600 {
  font-weight: 600;
}

.text300 {
  color: var(--text300);
}
.text400 {
  color: var(--text400);
}
.text500 {
  color: var(--text500);
}
.text600, .textbase {
    color: var(--textbase);
}

.center{
  text-align: center;
}