.upgradePlansContnet {
  display: grid;
  max-height: 90vh;
  padding: var(--space-24);
  overflow: auto;
}

.upgradeHeader {
  text-align: center;
  padding: var(--space-36) var(--space-64);
}
.upgradeHeader .upgradeHeaderHighlight {
  color: var(--blue500);
}

.plans {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
  max-width: 960px;
  justify-self: center;
}

.price {
  color: var(--green500);
  letter-spacing: -1.32px;
  line-height: 44px;
  padding-top: var(--space-12);
  padding-bottom: var(--space-24);
  height: 8rem;
}
.planSubText{
  height: 4rem;
}

.plan {
  border: 1px solid var(--bg400);
  position: relative;
}
.plan1 {
  border-right-width: 1px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.plan2 {
  border-left: none;
  border-right: none;
}
.plan3 {
  border-left-width: 1px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.planHeader {
  display: grid;
  justify-content: center;
  grid-row-gap: var(--space-12);
  text-align: center;
  padding: var(--space-32);
  height: 28.5rem;
}
.planContent {
  display: grid;
  grid-row-gap: var(--space-12);
  padding: var(--space-24) var(--space-32);
  border-top: 1px solid var(--bg400);
}

.planFeature{
  color: var(--text500);
  font-weight: 500;
}
.planFeature b{
  color: var(--textbase);
  font-weight: 600;
}

.mostPopular {
  position: absolute;
  background: var(--green500);
  color: var(--white);
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-8);
  top: -20px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.compareHeader {
  text-align: center;
  padding: var(--space-24) var(--space-8);
}

.comparePlans {
  display: grid;
  grid-auto-flow: column;
  /* grid-template-columns: 2fr 1fr 1fr 1fr; */
  grid-template-columns: 1fr 175px 175px 175px 175px;
  max-width: 960px;
  justify-self: center;
  padding-bottom: var(--space-12);
  width: 100%;
}
.comparePlans:last-child{
  padding-bottom: var(--space-36);
}

.features,
.plan1Feature,
.plan2Feature,
.plan3Feature {
  display: grid;
  grid-row-gap: var(--space-24);
  font-size: var(--font-size-16);
}

.plan1Feature,
.plan2Feature,
.plan3Feature {
  text-align: center;
}

.featureHeader {
  padding: var(--space-16) 0 var(--space-16) 0;
  border-bottom: 1px solid var(--bg400);
  height: 5rem;
  /* margin-bottom: var(--space-4); */
}

.iconParent {
  display: flex;
  justify-content: center;
}

.notIncludedIcon,
.includedIcon {
  font-size: var(--space-10);
  font-weight: 500;
  height: var(--space-16);
  display: flex;
  width: var(--space-16);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.notIncludedIcon {
  color: var(--white);
  background: var(--icon-medium);
}

.includedIcon {
  color: var(--white);
  background: var(--green500);
}
