.headerContent {
  display: grid;
  align-items: flex-end;
  text-align: left;
  grid-row-gap: 0.4rem;
}

.showPassword{
  align-self: start;
}

.resetPasswordForm :global(.ant-form-item) {
  margin-bottom: var(--space-16) !important;
}

.resetPasswordForm :global(.ant-form-item .ant-form-item-control-input-content input) {
  padding: var(--space-8) var(--space-12) !important;
}

.resetPasswordForm .confirmPasswordRow:global(.ant-form-item) {
  margin-bottom: var(--space-20) !important;
}