/* field weight form */

.fieldWeightForm {
  display: flex;
  margin-bottom: var(--space-16);
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  /* justify-content: center; */
  grid-template-columns: 1fr min-content 1fr min-content;
  grid-column-gap: 1rem;
  background: #f2f4f8;
  padding: var(--space-20);
  border-radius: 4px !important;
}

.fieldWeightForm:last-child {
  margin-bottom: var(--space-8);
}

.fieldWeightForm .field {
  display: grid;
  grid-row-gap: var(--space-4);
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--space-8);
  padding-top: var(--space-20);
}

/* add synonyms form */
.addSynonymForm {
  display: flex;
  margin-bottom: 8px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  /* justify-content: center; */
  grid-template-columns: 1fr min-content;
  grid-column-gap: 1rem;
}

/* add alias form */
.addAliasForm {
  display: flex;
  margin-bottom: 8px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  /* justify-content: center; */
  grid-template-columns: 1fr min-content;
  grid-column-gap: 1rem;
}

/* field settings collection confg */
.fieldSettingsForm {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr 1fr 8rem auto;
}

.fieldSettingsForm .field {
  display: grid;
  grid-row-gap: var(--space-4);
}

.fieldSettingsForm :global(.ant-row.ant-form-item) {
  margin-bottom: 0 !important;
}

.fieldSettingsForm {
  margin-bottom: var(--space-16);
}

/* search field form */

.queryFieldForm {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: 1fr min-content 1fr min-content;
  grid-column-gap: 1rem;
}

.queryFieldForm .field {
  display: grid;
  grid-row-gap: var(--space-4);
}

.searchArrow {
  padding: 0 var(--space-8);
}

/* create data source field */
.addDataSourceField {
  margin-bottom: var(--space-16);
  display: grid;
  align-items: center;
  /* justify-content: center; */
  grid-row-gap: 1rem;
  background: #f2f4f8;
  padding: var(--space-20);
  border-radius: 4px !important;
  position: relative;
}

.removeButton {
  position: absolute;
  top: var(--space-8);
  right: var(--space-8);
}

.searchFields {
  display: grid;
  background: var(--bg100);
  padding: var(--space-12);
  grid-row-gap: var(--space-8);
}

.searchFields > label {
  margin: 0 !important;
}

/* create indexing form */
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* datasource create, edit form */
.schedule {
  text-transform: capitalize;
}

/* Create Engine Form */
.engineHeader {
  display: grid;
  grid-row-gap: var(--space-8);
  padding-bottom: var(--space-16);
}

.fieldHeader {
  padding-bottom: var(--space-4);
  color: var(--text500);
}

/* form field spacing */
.createCollectionForm :global(.ant-form-item),
.createViewForm :global(.ant-form-item),
.createIndexingForm :global(.ant-form-item),
.updatePasswordForm :global(.ant-form-item),
.updateProfileForm :global(.ant-form-item) {
  margin-bottom: var(--space-20) !important;
}

.addFieldButton {
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: var(--space-4);
}

/* sitemap css */

.incluseSiteMap {
  margin-bottom: var(--space-12);
}
.sitemapPref {
  display: flex;
}

.siteMapContent {
  padding: var(--space-12) var(--space-16);
  border: 1px solid var(--blue300);
  border-radius: 8px;
}
.siteMapContent :global(.ant-row.ant-form-item) {
  margin-bottom: 0;
}
.siteMapHeader {
  padding-bottom: var(--space-8) !important;
}

.sitemapSettingsForm {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 1rem;
}

.sitemapSettingsForm svg {
  height: var(--space-16);
  width: var(--space-16);
}

.sitemapSettingsForm .field {
  display: grid;
  grid-row-gap: var(--space-4);
}

.sitemapSettingsForm :global(.ant-row.ant-form-item) {
  margin-bottom: 0 !important;
}

.sitemapSettingsForm {
  margin-bottom: var(--space-12);
}

.sitemapRadio {
  display: grid;
}
.plusicon {
  display: grid;
  align-items: center;
  justify-content: center;
}
.siteMapUrls {
  padding-top: var(--space-8);
}
.emptySiteMap {
  padding-top: var(--space-12);
}


/* editFieldSettings */

.fieldNameEditField{
  display: none;
}

.fieldNameEditFieldValue{
  margin: var(--space-4) 0 var(--space-16) 0;
}

/* editFieldSettings */
